import {
    Component,
    ComponentOptions,
    IComponentBindings,
    $$,
    QueryEvents,
    IBuildingQueryEventArgs,
    Initialization,
    InitializationEvents,
    Tab,
    IStringMap,
    get,
    IQueryResults,
    IQuery,
    Dom
} from 'coveo-search-ui';

import dayjs = require('dayjs');
import utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
// The dummy options have been removed, since they're no longer used.
export interface ITabResultCountOptions {
    resultcounttext: string;
    sitedatabase: string;
}
/**
 * The interface is declared, but not exported.
 * It's only used as an internal data structure for this component.
 */
interface ITabDefinition {
    tabElement: HTMLElement;
    tab: Tab;
    expression: string;
    pipeline: string;
    resultCountSection: Dom;
}
export class TabResultCount extends Component {
    static ID = 'TabResultCount';
    static options: ITabResultCountOptions = {
        resultcounttext: ComponentOptions.buildStringOption({ defaultValue: '' }),
        sitedatabase: ComponentOptions.buildStringOption({ defaultValue: 'master' })
    };
    /**
     * This data structure is used to interact with the tabs in the interface.
     * It will be a JSON mapping TabID to ITabDefinition.
     */
    private tabsDefinition: IStringMap<ITabDefinition> = {};
    constructor(public element: HTMLElement, public options: ITabResultCountOptions, public bindings: IComponentBindings) {
        super(element, TabResultCount.ID, bindings);
        this.options = ComponentOptions.initComponentOptions(element, TabResultCount, options);

        /**
         * After all components in the interface have been initialized 
         * (which implies that all tabs in the interface can now be interacted with), 
         * the populateTabsDefinitions property is populated.
         */
        this.bind.onRootElement(InitializationEvents.afterComponentsInitialization, () => this.populateTabsDefinitions());
        /**
         * During every query, the required subqueries are executed to get the result count.
         */
        //this.bind.onRootElement(QueryEvents.duringQuery, () => this.handleDuringQuery());
        this.bind.onRootElement(QueryEvents.doneBuildingQuery, () => this.handleDuringQuery());
    }
    /**
     * Returns the last executed query 
     * (see https://coveo.github.io/search-ui/interfaces/iquery.html).
     */
    private handleDuringQuery() {
        let lastQuery = this.queryController.getLastQuery();
        let resultCountText = this.options.resultcounttext;
        /**
         * Iterates over all tab expressions, and checks if the tabDefinition is the currently active tab.
         * When it's not, it sends a query to get the correct result count for that tab.
         * 
         * You don't want to call Coveo.executeQuery, as it would alert the whole interface that a query was performed.
         * Calling the search point directly doesn't have that effect.
         * 
         * When the results from the query are returned, a new section is added to the tab to hold the result count.
         */
        _.each(this.tabsDefinition, (tabDefinition: ITabDefinition) => {
            this.cleanOldResultCount(tabDefinition);
            if (this.queryStateModel.get('t') != tabDefinition.tab.options.id) {
                let queryToExecuteForThisTabCount = this.getQueryForTabCount(lastQuery, tabDefinition);
                this.queryController.getEndpoint()
                    .search(queryToExecuteForThisTabCount)
                    .then((response: IQueryResults) => {
                        let totalCount = response.totalCount;
                        let section = $$('span', { className: 'my-count-section' }, `(${totalCount.toString(10)} ${resultCountText})`);
                        let sectionElement = tabDefinition.tabElement.getElementsByClassName('my-count-section')[0];
                        if (sectionElement != undefined) {
                            sectionElement.remove();
                        }
                        tabDefinition.tabElement.appendChild(section.el);
                        tabDefinition.resultCountSection = section
                    })
            }
        })
    }
    /**
     * Finds all tabs in the interface, suing their CSS class name.
     * It then iterates over each element to get the Tab component instance.
     * The instance is then used to build the tabsDefinitions property.
     */
    private populateTabsDefinitions() {
        if (!Tab) {
            return;
        }

        let allTabsInInterface = $$(this.root).findAll(`.${Component.computeCssClassNameForType(Tab.ID)}`);
        _.each(allTabsInInterface, (tabElement: HTMLElement) => {
            let tab = <Tab>get(tabElement);
            this.tabsDefinition[tab.options.id] = {
                tabElement: tabElement,
                tab: tab,
                expression: tab.options.expression,
                pipeline: tab.options.pipeline,
                resultCountSection: null
            }
        });
    }
    /**
     * Resets the result count on every new query.
     * 
     * @param tabDefinition The tab from which to remove the current query results.
     */
    private cleanOldResultCount(tabDefinition: ITabDefinition) {
        if (tabDefinition.resultCountSection != null) {
            tabDefinition.resultCountSection.remove();
            tabDefinition.resultCountSection = null;
        }
    }
    /**
     * Builds the query that needs to be executed to get the correct result count for each tab.
     * The tab expression is added as an aq (advanced query) to the last performed query.
     * The cq (constant query) is then cleared, as it uses the currently selected tab.
     * Clearing it allows your queries to be identitical except for the specified tab.
     * 
     * @param lastQuery The last query that was performed.
     * @param tabDefinition The current tab.
     */
    private getQueryForTabCount(lastQuery: IQuery, tabDefinition: ITabDefinition) {
        let queryToExecuteForThisTabCount = _.clone(lastQuery);

        let queryfacets = '';

        let tabexpression = '';
        let customizedQuery = '';
        
        if (tabDefinition.tab.options.id == "blogs-search") {

            let facetpretextstring = '';
            let facetpretext = this.queryStateModel.get('f:facetpretext')
            _.each(facetpretext, (facetElement: string) => {
                if (facetpretextstring == '') {
                    facetpretextstring = `"${facetElement}"`
                }
                else {
                    facetpretextstring = `${facetpretextstring},"${facetElement}"`
                }
            });

            let facettopicsstring = '';
            let facettopics = this.queryStateModel.get('f:facettopics')
            _.each(facettopics, (facetElement: string) => {
                if (facettopicsstring == '') {
                    facettopicsstring = `"${facetElement}"`
                }
                else {
                    facettopicsstring = `${facettopicsstring},"${facetElement}"`
                }
            });


            let minContentDate='';
            let maxContentDate='';
            let facetcontentpublisheddate = this.queryStateModel.get('f:facetcontentpublisheddate:range')
            if (facetcontentpublisheddate != undefined) {
                let minunixtime = Number(this.queryStateModel.get('f:facetcontentpublisheddate:range')[0])
                let maxunixtime = Number(this.queryStateModel.get('f:facetcontentpublisheddate:range')[1])
                minContentDate = dayjs(minunixtime).utc().format("YYYY/MM/DD@HH:mm:ss");
                maxContentDate = dayjs(maxunixtime).utc().format("YYYY/MM/DD@HH:mm:ss");
            }


            if (facetpretextstring != null && facetpretextstring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@prez45xtez120xt==(${facetpretextstring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@prez45xtez120xt==(${facetpretextstring}))`;
                }
            }

            if (facettopicsstring != null && facettopicsstring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@tags==(${facettopicsstring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@tags==(${facettopicsstring}))`;
                }
            }

            var pagehref = window.location.href;
            if (pagehref.indexOf("facetcontentpublisheddate") > 0) {
                if (facetcontentpublisheddate != undefined) {
                    if (minContentDate != null && minContentDate != '' && maxContentDate != null && maxContentDate != '') {
                        if (queryfacets == '') {
                            queryfacets = `(@contentpublisheddate==${minContentDate}..${maxContentDate})`;
                        }
                        else {
                            queryfacets = `${queryfacets} (@contentpublisheddate==${minContentDate}..${maxContentDate})`;
                        }
                    }
                }
            }
        }




        if (tabDefinition.tab.options.id == "others-search") {

            let facetsearchcontentpagetypestring = '';
            let facetsearchcontentpagetype = this.queryStateModel.get('f:facetsearchcontentpagetype')
            _.each(facetsearchcontentpagetype, (facetElement: string) => {
                if (facetsearchcontentpagetypestring == '') {
                    facetsearchcontentpagetypestring = `"${facetElement}"`
                }
                else {
                    facetsearchcontentpagetypestring = `${facetsearchcontentpagetypestring},"${facetElement}"`
                }
            });

            if (facetsearchcontentpagetypestring != null && facetsearchcontentpagetypestring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@facetsearchcontentpagetype==(${facetsearchcontentpagetypestring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@facetsearchcontentpagetype==(${facetsearchcontentpagetypestring}))`;
                }
            }

        }





        if (tabDefinition.tab.options.id == "resources-search") {


            let facetassettypestring = '';
            let facetassettype = this.queryStateModel.get('f:facetassettype')
            _.each(facetassettype, (facetElement: string) => {
                if (facetassettypestring == '') {
                    facetassettypestring = `"${facetElement}"`
                }
                else {
                    facetassettypestring = `${facetassettypestring},"${facetElement}"`
                }
            });

            let facetbrandstring = '';
            let facetbrand = this.queryStateModel.get('f:facetbrand')
            _.each(facetbrand, (facetElement: string) => {
                if (facetbrandstring == '') {
                    facetbrandstring = `"${facetElement}"`
                }
                else {
                    facetbrandstring = `${facetbrandstring},"${facetElement}"`
                }
            });

            let facetmarketstring = '';
            let facetmarket = this.queryStateModel.get('f:facetmarket')
            _.each(facetmarket, (facetElement: string) => {
                if (facetmarketstring == '') {
                    facetmarketstring = `"${facetElement}"`
                }
                else {
                    facetmarketstring = `${facetmarketstring},"${facetElement}"`
                }
            });

            let facetsolutionstring = '';
            let facetsolution = this.queryStateModel.get('f:facetsolution')
            _.each(facetsolution, (facetElement: string) => {
                if (facetsolutionstring == '') {
                    facetsolutionstring = `"${facetElement}"`
                }
                else {
                    facetsolutionstring = `${facetsolutionstring},"${facetElement}"`
                }
            });

            let facetlanguagestring = '';
            let facetlanguage = this.queryStateModel.get('f:facetlanguage')
            _.each(facetlanguage, (facetElement: string) => {
                if (facetlanguagestring == '') {
                    facetlanguagestring = `"${facetElement}"`
                }
                else {
                    facetlanguagestring = `${facetlanguagestring},"${facetElement}"`
                }
            });

            if (facetassettypestring != null && facetassettypestring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@facetassettype==(${facetassettypestring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@facetassettype==(${facetassettypestring}))`;
                }
            }

            if (facetbrandstring != null && facetbrandstring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@facetbrand==(${facetbrandstring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@facetbrand==(${facetbrandstring}))`;
                }
            }

            if (facetmarketstring != null && facetmarketstring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@facetmarket==(${facetmarketstring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@facetmarket==(${facetmarketstring}))`;
                }
            }

            if (facetsolutionstring != null && facetsolutionstring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@facetsolution==(${facetsolutionstring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@facetsolution==(${facetsolutionstring}))`;
                }
            }

            if (facetlanguagestring != null && facetlanguagestring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@parsedlanguage==(${facetlanguagestring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@parsedlanguage==(${facetlanguagestring}))`;
                }
            }

        }



        if (tabDefinition.tab.options.id == "products-search") {


            let facetproductcategorystring = '';
            let facetproductcategory = this.queryStateModel.get('f:facetproductcategory')
            _.each(facetproductcategory, (facetElement: string) => {
                if (facetproductcategorystring == '') {
                    facetproductcategorystring = `${facetElement}`
                }
                else {
                    facetproductcategorystring = `${facetproductcategorystring}|${facetElement}`
                }
            });

            let productbrandstring = '';
            let productbrand = this.queryStateModel.get('f:productbrand')
            _.each(productbrand, (facetElement: string) => {
                if (productbrandstring == '') {
                    productbrandstring = `"${facetElement}"`
                }
                else {
                    productbrandstring = `${productbrandstring},"${facetElement}"`
                }
            });

            let productapplicationstring = '';
            let productapplication = this.queryStateModel.get('f:productapplication')
            _.each(productapplication, (facetElement: string) => {
                if (productapplicationstring == '') {
                    productapplicationstring = `"${facetElement}"`
                }
                else {
                    productapplicationstring = `${productapplicationstring},"${facetElement}"`
                }
            });

            let productenvironmentstring = '';
            let productenvironment = this.queryStateModel.get('f:productenvironment')
            _.each(productenvironment, (facetElement: string) => {
                if (productenvironmentstring == '') {
                    productenvironmentstring = `"${facetElement}"`
                }
                else {
                    productenvironmentstring = `${productenvironmentstring},"${facetElement}"`
                }
            });

            let regionalavailabilitystring = '';
            let regionalavailability = this.queryStateModel.get('f:regionalavailability')
            _.each(regionalavailability, (facetElement: string) => {
                if (regionalavailabilitystring == '') {
                    regionalavailabilitystring = `"${facetElement}"`
                }
                else {
                    regionalavailabilitystring = `${regionalavailabilitystring},"${facetElement}"`
                }
            });


            if (facetproductcategorystring != null && facetproductcategorystring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@catalogitemcategories==("${facetproductcategorystring}"))`;
                }
                else {
                    queryfacets = `${queryfacets} (@catalogitemcategories==("${facetproductcategorystring}"))`;
                }
            }

            if (productbrandstring != null && productbrandstring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@catalogitembrand==(${productbrandstring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@catalogitembrand==(${productbrandstring}))`;
                }
            }

            if (productapplicationstring != null && productapplicationstring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@catalogitemapplication==(${productapplicationstring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@catalogitemapplication==(${productapplicationstring}))`;
                }
            }

            if (productenvironmentstring != null && productenvironmentstring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@catalogitemenvironment==(${productenvironmentstring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@catalogitemenvironment==(${productenvironmentstring}))`;
                }
            }

            if (regionalavailabilitystring != null && regionalavailabilitystring != '') {
                if (queryfacets == '') {
                    queryfacets = `(@catalogitemregionalavailability==(${regionalavailabilitystring}))`;
                }
                else {
                    queryfacets = `${queryfacets} (@catalogitemregionalavailability==(${regionalavailabilitystring}))`;
                }
            }

        }

        queryToExecuteForThisTabCount.cq = tabDefinition.tab.element.dataset.constantqueryexpression;

        tabexpression = tabDefinition.tab.element.dataset.queryexpression;
        var re = /Coveo_master_index/gi;

        if (this.options.sitedatabase == "web") {
            tabexpression=tabexpression.replace(re, "Coveo_web_index");
            queryToExecuteForThisTabCount.cq=queryToExecuteForThisTabCount.cq.replace(re, "Coveo_web_index");
        }
        
        customizedQuery = `${queryfacets} ${tabexpression}`;

        queryToExecuteForThisTabCount.aq = `${customizedQuery}`

        let queryParameter = this.queryStateModel.get('q');
        if (queryParameter != null && queryParameter != '') {
            queryToExecuteForThisTabCount.q = queryParameter;
        }
        else {
            queryToExecuteForThisTabCount.q = null;
        }

        queryToExecuteForThisTabCount.pipeline = "Site Search";

        

        return queryToExecuteForThisTabCount;
    }
}
Initialization.registerAutoCreateComponent(TabResultCount);
