// This entry point defines all the component that should be included in your extension.

//export { HelloWorld } from './ui/HelloWorld';

import {
  Component,
  Initialization,
  LazyInitialization,
  ComponentOptions,
  IComponentBindings,
  IComponentDefinition,
  get,
  load,
  $$,

  IClickEvent
} from 'coveo-search-ui';
import {
  SearchboxDropdown
} from './SearchboxDropdown';
export interface ISearchboxDropdownItemOptions {
  caption: string;
  target?: string;
  default?: boolean;
  position?: string;
}
export class SearchboxDropdownItem extends Component {
  static ID = 'SearchboxDropdownItem';
  /**
   * The options for the component.
   * @componentOptions
   */
  static options: ISearchboxDropdownItemOptions = {
    /**
     * Specifies the caption to be displayed on the component.
     */
    caption: ComponentOptions.buildStringOption({defaultValue: ''}),
    /**
     * Specifies the target of the component. To be used in your
     * implementation, for instance, in the toggle() function below.
     */
    target: ComponentOptions.buildStringOption({defaultValue: ''}),
    /**
     * Specifies whether the component is displayed when the search
     * page first loads.
     */
    default: ComponentOptions.buildBooleanOption({defaultValue: false}),
    /**
     * Specifies the position of the component.
     */
    position: ComponentOptions.buildStringOption({defaultValue: ''})
  };
  private searchboxDropdown: SearchboxDropdown = null;
  constructor(public element: HTMLElement,
              public options: ISearchboxDropdownItemOptions,
              public bindings?: IComponentBindings) {
    super(element, SearchboxDropdownItem.ID, bindings);
    this.options = ComponentOptions.initComponentOptions(
                      element, SearchboxDropdownItem, options);
    //...
    this.renderComponent();
  };
   /**
   * Toggles the selected item.
   */
  public toggle(event) {
    //this.searchboxDropdown.selectItem(this);
	 // This is most likely where you would include code to
    // affect the query, probably using this.options.target
	//let renderedSelectedItem =$$(this.root).findAll(`.coveo-custom-searchbox-dropdown-selected`)[0];
    //  renderedSelectedItem.dataset.destination = this.options.target;

      let otherDropdown = document.getElementsByClassName(`coveo-custom-searchbox-dropdown`);
      _.each(otherDropdown, (searchdropdownelement: HTMLElement) => {
          let searchboxDropdownElement = <SearchboxDropdown>get(searchdropdownelement);
          searchboxDropdownElement.selectItem(this)
      });
      

      let otherDropdownItem = document.getElementsByClassName(`coveo-custom-searchbox-dropdown-selected`);

      _.each(otherDropdownItem, (searchDropdownSelectedelement: HTMLElement) => {
          searchDropdownSelectedelement.dataset.destination = this.options.target;
      });
      const targetElement: any = $$(event.currentTarget);
      let activeElement = document.querySelectorAll('.coveo-custom-searchbox-dropdown-content a');
      _.each(activeElement , (anchorElement: HTMLAnchorElement) =>{
        anchorElement.classList.remove('active');
        if(anchorElement.text === targetElement.text()) {
          anchorElement.classList.add('active');
        }
      });
    }
    private handleClick(e) {
    this.toggle(e);
  };
  private renderComponent() {
    this.searchboxDropdown = <SearchboxDropdown>get(this.element.parentElement);
    let link = $$('a', {}, this.options.caption).el;
    let item = $$('li', {class: this.options.position}, '', link).el;
    $$(link).on('click', (e) => this.handleClick(e));
    if (this.options.default) {
      this.searchboxDropdown.selectItem(this);
    }
    this.searchboxDropdown.itemList.appendChild(item);
  };
};
export function lazySearchboxDropdownItem() {
  return load<IComponentDefinition>('SearchboxDropdown').then(
                                            (SearchboxDropdown) => {
    Initialization.registerAutoCreateComponent(SearchboxDropdownItem);
    return SearchboxDropdownItem;
  });
};
// Register the 'SearchboxDropdownItem' lazy component using
// the previously defined function.
LazyInitialization.registerLazyComponent('SearchboxDropdownItem',
                                         lazySearchboxDropdownItem);
